import React, { Component } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { FaCheckSquare } from 'react-icons/fa';

import FeatureIcon1 from '../../images/features/grow-your-email-list/email-capture.png'
import FeatureIcon2 from '../../images/features/grow-your-email-list/coupon.png'
import FeatureIcon3 from '../../images/features/grow-your-email-list/embed-popup.png'
import FeatureIcon4 from '../../images/features/grow-your-email-list/giveaway-options.png'

class HomePopupTriggers extends Component {
    render() {
        return (
            <>
                <div className="home-popup-features-section bg-white">
                    <div className="container">
                        <Row className="align-items-center text-center pt-3">
                            <Col md="12">
                                <div className="hero-content wow fadeIn">
                                    <h2>How Optinly Can Help  <span className="style-highlight"> Grow Your Email List
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span></h2>
                                </div>
                            </Col>
                        </Row>
                        <Row className="align-items-center py-5">
                            <Col md="6">
                                <div className="mb-4 ps-3 pe-5">
                                    <h3>Use Lead Magnets to Capture Email Addresses</h3>
                                    <p>One of the best ways to grow an email list is to give the audience something useful. And in return, ask for their email address. This common practice is a win-win scenario and is an ideal email list building idea.</p>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-4">
                                    <img src={FeatureIcon1} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div class="elementor-shape elementor-shape-bottom" data-negative="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path class="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                    <path class="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                    <path class="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                    </svg> </div>
                </div>
                <div className="home-popup-features-section">
                    <div className="container">
                        <Row className="align-items-center py-5">
                            <Col md="6">
                                <div className="mb-4">
                                    <img src={FeatureIcon2} alt="" />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-4 ps-5 pe-3">
                                    <h3>Use Coupons/Offer Discounts</h3>
                                    <p>Deliver coupons or display offers to your store visitors. You can simply create a coupon and allow users to copy it to the clipboard. Meanwhile, on the other end, you grow your email list with ease.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div class="elementor-shape elementor-shape-bottom" data-negative="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path class="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                    <path class="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                    <path class="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                    </svg> </div>
                </div>
                <div className="home-popup-features-section bg-white">
                    <div className="container">
                        <Row className="align-items-center pt-5">
                            <Col md="6">
                                <div className="mb-4 ps-3 pe-5">
                                    <h3>Use Embed Popups on Blogs</h3>
                                    <p>Blogs that drive organic traffic are potent sources to grow your email marketing list. And when there’s a good engagement rate on your website, you can go ahead and launch an embed popup campaign – helps grow your email list steadily.</p>
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-4">
                                    <img src={FeatureIcon3} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div class="elementor-shape elementor-shape-bottom" data-negative="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path class="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                    <path class="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                    <path class="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                    </svg> </div>
                </div>
                <div className="home-popup-features-section">
                    <div className="container">
                        <Row className="align-items-center py-5">
                            <Col md="6">
                                <div className="mb-4">
                                    <img src={FeatureIcon4} alt="" />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-4 ps-5 pe-3">
                                    <h3>Offer Membership/Product Trials</h3>
                                    <p>Free product/membership trials are the best giveaway options when you run a community or a SaaS company. Let your audience treat themselves with what you have to offer in return for their email address.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div class="elementor-shape elementor-shape-bottom" data-negative="false">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                    <path class="elementor-shape-fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                    <path class="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                    <path class="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
                    </svg> </div>
                </div>
            </>
        )
    }
}

export default HomePopupTriggers
