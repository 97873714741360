import React from 'react'
import Layout from '../../components/layout'
import Metatags from "../../components/Metatags"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
//import home page components
import GetStarted from '../../components/getstarted'
import Banner from '../../components/Banner';
import HomeIntegrationCompanies from '../../constants/Home/IntegrationCompanies';
import HomeCounter from '../../constants/Home/homeCounter';
import HomePopupFeatures from '../../constants/GrowYourEmailList/HomePopupFeatures';
import HomePopupTriggerRules from '../../constants/GrowYourEmailList/HomePopupTriggerRules';
import HomePopupClients from '../../constants/EmailPopups/HomePopupClients';
import SwiperSlider from '../../components/SwiperSlider';
import Bg from '../../images/background-1.png';
import SliderImg1 from '../../images/mike.png';
import SliderImg2 from '../../images/jockbrocas.png';
import SliderImg3 from '../../images/caseyjones.png';

const Title = () => (
    <><h1>Grow Your <span className="style-highlight">Email List
        <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg>
    </span></h1></>
);

const Slides = [
    {
        content: `
            <div className="swiper-slide-contents">
                <div className="elementor-slide-heading">"Was Not a fan of Popups, Until Now!"</div>
                <div className="elementor-slide-description">What I like here is, there is no subscriber limit and their templates are amazing. It also has a dashboard to monitor conversions/campaigns for all my website/clients!<br/><br/>
                <b>Mike</b> - mrdzyn.com 
                </div>
            </div>`,
        img: SliderImg1
    },
    {
        content: `
            <div className="swiper-slide-contents"><div className="elementor-slide-heading">"I Ditched Optinmonster&#8203;"</div><div className="elementor-slide-description">This has been the answer to my needs. I have jumped all in and now that I have played with it, I am slowly moving all my sites over to Optinly.<br><br><b>JockBrocas</b> - jockbrocas.com</div>
            </div>`,
        img: SliderImg2
    },
    {
        content: `
            <div className="swiper-slide-contents"><div className="elementor-slide-heading">Love Optinly!</div><div className="elementor-slide-description">One word pretty well sums it up - Awesome!<br><br>
            <b>Casey Jones</b> - www.cjco.com.au</div></div>`,
        img: SliderImg3
    },
]

const SwiperSlides = () => (
    <div className="position-relative">
        <SwiperSlider slidesPerView={1} slides={Slides} spaceBetween={0} slideDeley={5000} />
    </div>
);

const SpinTheWheelPopups = () => (
    <Layout className="landing-page">
        <Metatags
            title="Grow Your Email List with Optinly"
            description="Grow your email list with Optinly's 100+ responsive, ready-made templates. 10x your sign-ups and sales."
            keywords={[`exit popup`, `popup maker`, `free exit popup generator`, `email optins Features Page`]}
            url="https://www.optinly.com"
            pathname="/"
        />
        <Banner title={<Title />} description="Start growing your email subscriber list today with Optinly. Highly converting email popup templates to engage website visitors. Gently guide your website visitors to sign up for email list." actionUrl="https://app.optinly.com/?utm_source=Landing&utm_medium=cta&utm_campaign=emailpopups" actionText={"Start Growing Your Email List"} slider={<SwiperSlides />} bgImg={Bg} />

        <HomeIntegrationCompanies />
        <HomeCounter />
        <HomePopupFeatures />
        <HomePopupTriggerRules />
        <HomePopupClients />
        <GetStarted title="Boost Sales & Sign Up 10x Times With Email Popups" />
        <div className="py-5 faq-section">
            <div className="container py-3">
                <div className="mb-5 text-center hero-content wow fadeIn">
                    <h2>Frequently Answered Questions</h2>
                </div>
                <Accordion allowZeroExpanded>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            What is the maximum number of lead generation popup campaigns I can create with Optinly?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Optinly lead generation tool allows you to create  unlimited email popup campaigns. Also, there is no limit on the number of pageviews/sessions.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            Can I display an email popup when users exit the webpage?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>
                            Yes. You can use Optinly’s exit trigger option to display popups when a user tries to abandon the webpage.
                            </p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            Can I add multiple popup triggering options to the same campaign?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>You can use a combination of popup triggers to make your list-building campaign more effective. For example, you can use a time-delay trigger for a popup campaign and run it on selected pages using URL-based triggers.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            Can I view captured leads within Optinly?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Yes. Optinly has a separate section in its dashboard which allows you to take a look at the captured leads. You can also view leads campaign-wise and download the same via a CSV file.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                            Are there any analytics with which campaign metrics can be measured?
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <p>Optinly lets you know important campaign metrics like the number of clicks, views and responses. You can also view the type of popup used with icons and the date when you created your popup.</p>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>

    </Layout>
);

export default SpinTheWheelPopups;