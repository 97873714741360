import React, { Component } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { FaArrowRight } from 'react-icons/fa';

import FeatureIcon1 from '../../images/features/grow-your-email-list/choose-templates-1.png'
import FeatureIcon2 from '../../images/features/grow-your-email-list/popup-editor-1.png'
import FeatureIcon3 from '../../images/features/grow-your-email-list/multiple-popups.png'
import FeatureIcon4 from '../../images/features/grow-your-email-list/popup-form.png'

class HomePopupFeatures extends Component {
    render() {
        return (
            <div className="home-popup-features-section">
                <div className="container">
                    <Row className="align-items-center text-center py-3">
                        <Col md="12">
                            <div className="hero-content wow fadeIn">
                                <h2>Powerful Features to Help With Your <span className="style-highlight"> Email List Building 
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#FFFF00" viewBox="0 0 500 150" preserveAspectRatio="none"><path d="M7.7,145.6C109,125,299.9,116.2,401,121.3c42.1,2.2,87.6,11.8,87.3,25.7"></path></svg></span></h2>
                                <p className="w-100">A readymade lead generation tool that helps you to capture leads for email marketing seamlessly. Power-packed with the best industry features, start growing your email subscriber list right away with Optinly!</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center py-5">
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon1} alt="" />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4 ps-5 pe-3">
                                <h3>100+ Readymade Responsive Popup Templates</h3>
                                <p>Optinly comes with an inbuilt popup template gallery with templates that are responsive, customizable and visually appealing. You can simply choose an email popup template you like and start creating your campaign.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center py-5">
                        <Col md="6">
                            <div className="mb-4 ps-3 pe-5">
                                <h3>Advanced Built-in Popup Editor</h3>
                                <p>Customize email popup templates extensively with Optinly’s built-in popup editor. You can add/replace images, change font styling, add custom URLs and do more. Also, check the responsiveness of the popup template using the device preview option.</p>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon2} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center py-5">
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon3} alt="" />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4 ps-5 pe-3">
                                <h3>Multiple Popup Triggering Options</h3>
                                <p>The best way to grow your email list is to display lead generating popups at the most appropriate and relevant time. Use Optinly’s multiple popup display rules to show popups when visitors anticipate – exit triggers, device-based triggers, page-based triggers and more.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center pt-5">
                        <Col md="6">
                            <div className="mb-4 ps-3 pe-5">
                                <h3>6+ Types of Popup Forms</h3>
                                <p>Capture emails using different types of email collecting popups. Use Optinly’s 6+ types of popups to engage web audience and capture leads – spin the wheel popups, popup launcher, floating bars, fullscreen overlays and more.</p>
                                <p>
                                    <a className="btn btn-secondary mt-3" href="https://app.optinly.com/?utm_source=Landing&utm_medium=cta&utm_campaign=emailpopups" target="_blank">
                                    Create Lead Generation Popup Campaign <FaArrowRight />
                                    </a>
                                </p>
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-4">
                                <img src={FeatureIcon4} alt="" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

export default HomePopupFeatures
